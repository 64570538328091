<template>
  <div :class="{ active: isMenuOpen }" class="fd-sidebar" ref="sidebar">
    <div class="top">
      <router-link
        v-if="showLogo"
        :to="{ name: homepage }"
        class="logo d-block"
      >
        <img :src="require('@/assets/image/logo/logo.png')" class="logo" />
      </router-link>
      <slot name="top"></slot>
    </div>
    <div class="bottom">
      <nav>
        <ul class="m-0">
          <slot></slot>
          <br />
          <sidebar-item
            label="Logout"
            iconClass="fas fa-sign-out-alt icon"
            :routerLink="false"
            @click="logout()"
          ></sidebar-item>
        </ul>
      </nav>
      <div
        class="d-flex justify-content-center py-1 app-version"
        @click="$emit('toChangelog')"
      >
        <span v-if="appVersion" class="fg-grey-500">
          <i class="fas fa-code-branch mr-1"></i>
          Version: {{ appVersion }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import "./style/sidebar.scss";
import "@fortawesome/fontawesome-free/css/all.min.css";

export default {
  components: {
    SidebarItem: () =>
      import(
        "@/components/GlobalComponents/FdLayout/SidebarComponents/SidebarItem.vue"
      )
  },
  props: {
    homepage: {
      type: String,
      default: ""
    },
    showLogo: {
      type: Boolean,
      default: true
    }
  },
  data: function () {
    return {};
  },
  computed: {
    isMenuOpen() {
      return this.$store.getters.getSidebarState;
    },
    appVersion() {
      return process.env.VUE_APP_VERSION;
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.$nextTick(() => {
      document.querySelectorAll(".sidebar-item").forEach((each) => {
        each.addEventListener("click", () => {
          if (window.innerWidth <= 768) {
            this.closeMenu();
          }
        });
      });
    });
  },
  methods: {
    closeMenu() {
      this.$store.commit("closeSidebar");
    },
    async logout() {
      var c = await this.$confirm({
        message: "Are you sure you want to logout?",
        confirmText: "Logout"
      });

      if (c) {
        if (this.$auth.user().fcmToken != null) {
          await this.$store.dispatch(
            "login/removeFcm",
            this.$auth.user().fcmToken
          );
        }

        // Remove permission
        localStorage.removeItem(this.$permissionKey);

        this.$auth.logout({
          makeRequest: false,
          redirect: { path: "/login" }
        });
      }
    }
  }
};
</script>

<style lang="scss">
.fd-sidebar {
  .app-version {
    font-size: 12px;
    border-top: solid 1px #ddd;
    &:hover {
      text-decoration: underline;
      text-decoration-color: #b5b5b5;
      cursor: pointer;
    }
  }
}
</style>
